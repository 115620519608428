import { useState, useEffect } from 'react';
import Link from '@old/components/common/Link';
import ButtonGoogle from '@old/components/custom/ButtonGoogle';
import ButtonFacebook from '@old/components/custom/ButtonFacebook';
import t from 'resources/translations';
import FlexRow from '@old/components/common/FlexRow';
import Model from '@old/model';
import FlexColumn from '@old/components/common/FlexColumn';
import Center from '@old/components/common/Center';
import BoxWhite from '@old/components/common/BoxWhite';
import BoxShadow from '@old/components/common/BoxShadow';
import Form from '@old/components/common/Form';
import FormButton from '@old/components/common/FormButton';
import Field from '@old/components/field';
import { FORM_LOGIN } from 'old/constants/formNames';
import { LogoHmNotextIcon } from 'old/assets/svg/index';
import { getInitValues } from 'old/utils';
import { version } from '../../../package.json';
import { login } from 'store/auth';
import { useAppDispatch } from 'utils';

const Login = () => {
  const [apiVersion, setApiVersion] = useState();
  const dispatch = useAppDispatch();

  const onSubmit = async formData => dispatch(login({ email: formData.email, password: formData.password }));

  useEffect(() => {
    const fetchData = async () => {
      const result = await Model.Farms.apiVersion();
      setApiVersion(result.version);
    };
    fetchData();
  }, []);

  return (
    <BoxShadow className="page-login">
      <div className="login-banner">
        <LogoHmNotextIcon width={120} height={120} />
      </div>
      <BoxWhite padding>
        <FlexColumn>
          <FlexColumn>
            <h1 className="text-headline">
              {t('login.welcomeIn')} HORSE<span className="font-normal">manago</span>!
            </h1>
            {t('login.createLife')}
          </FlexColumn>

          <Form formName={FORM_LOGIN} noPadding initValues={getInitValues({ formName: 'login' })}>
            <Field.Email name="email" label={t('labels.email')} placeholder={t('placeholders.email')} required />
            <Field.Password
              name="password"
              label={t('labels.password')}
              placeholder={t('placeholders.password')}
              required
            />

            <Link to="/forgot-password" className="float-right text-link">
              {t('login.notRememberPassword')}
            </Link>

            <FormButton formName={FORM_LOGIN} fluid onClick={onSubmit}>
              {t('login.logIn')}
            </FormButton>
          </Form>

          <ButtonGoogle />
          <ButtonFacebook />

          <FlexRow>
            {t('login.notAccount')}
            <Link to="/register" className="text-link uppercase float-right">
              {t('login.registerIn')}
            </Link>
          </FlexRow>
          <Center className="text-fade">
            Version {version} / API {apiVersion}
          </Center>
        </FlexColumn>
      </BoxWhite>
    </BoxShadow>
  );
};

export default Login;
