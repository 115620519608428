import { useDispatch } from 'react-redux';
import GoogleLogin from 'react-google-login';

import { GoogleIcon } from 'old/assets/svg';
import t from 'resources/translations';
import ButtonOutline from '@old/components/guide/ButtonOutline';
import { socialLogin } from 'store/auth';
import { useHistory } from 'react-router';

const ButtonGoogle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const onSuccess = ({ tokenId }) => {
    const { pathname } = window.location;
    dispatch(socialLogin({ service: 'google', accessToken: tokenId, farmSlug: pathname, history }));
  };

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
      buttonText={t('googleLoginButton.googleSignIn')}
      render={renderProps => (
        <ButtonOutline fluid onClick={renderProps.onClick} disabled={renderProps.disabled}>
          <GoogleIcon />
          <span className="ml-1">{t('googleLoginButton.googleSignIn')}</span>
        </ButtonOutline>
      )}
      onSuccess={onSuccess}
    />
  );
};

export default ButtonGoogle;
